import SelectField from "@/components/common/fields/SelectField"
import { useMemo, useEffect } from "react"
import { mapOptions } from "@/utils/custom-field-utils"
import { useField } from "formik"

const CreateEditPartyCustomQueueSelector = (props) => {
  const {
    cf,
    publicQueueSelectHandler,
    setFieldValue,
    setCustomQueueSelectValue,
    values,
    setErrors,
    setTouched,
    handleQueueIdChange,
    waitTimes,
    setGuestQueueId,
  } = props

  const [field, meta, helpers] = useField("customQueueSelect")

  // get field props
  const fieldProps = useMemo(() => {
    return {
      key: cf?.index,
      name: `customQueueSelect`,
      label: cf?.name,
      required: cf?.isRequired,
      icon: cf?.icon,
    }
  }, [cf])

  const selectOptions = useMemo(() => {
    return {
      options: mapOptions(cf?.options, waitTimes),
    }
  }, [cf])

  useEffect(() => {
    // const queueId = field.setGuestQueueId()
  }, [field])

  return (
    <SelectField
      fullWidth
      showEmptyOption={false}
      onChange={(e) => {
        const valueMeta = selectOptions.options.find((option) => {
          return option.value === e.target.value
        }).valueMeta
        if (publicQueueSelectHandler) {
          publicQueueSelectHandler(
            e.target.value,
            setFieldValue,
            fieldProps.name,
            valueMeta
          )
        } else {
          setCustomQueueSelectValue(e.target.value)
          handleQueueIdChange(
            valueMeta,
            values,
            setFieldValue,
            setErrors,
            setTouched
          )
          setFieldValue(fieldProps.name, e.target.value)
        }
      }}
      {...fieldProps}
      {...selectOptions}
    />
  )
}

export default CreateEditPartyCustomQueueSelector
