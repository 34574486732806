import useSWR from "swr"
import { getWaitTimesByLocationId } from "@/api/nextme/wait-times"
import { refreshIntervals } from "@/consts/swr"
import { Location } from "@/api/interface/location"
import { useMemo } from 'react'

export default function useWaitTimesByLocationId(locationId: Location['id']) {
  const {
    data: waitTimes,
    isLoading,
    isValidating,
    mutate: mutateWaitTimes,
    error,
  } = useSWR(
    locationId ? `/nextme/wait-times/${locationId}` : null,
    () => getWaitTimesByLocationId(locationId),
    {
      fallbackData: [],
      refreshInterval: refreshIntervals.quicker,
      revalidateOnFocus: true,
      refreshWhenHidden: true,
      revalidateIfStale: true,
    }
  )

  const orderedWaitTimes = useMemo(() => {
    return waitTimes?.sort((a, b) => {
      if (a.index > b.index) {
        return 1
      }
      if (a.index < b.index) {
        return -1
      }
      return 0
    })
  }, [waitTimes])

  return {
    isValidating,
    isLoading,
    waitTimes: orderedWaitTimes,
    mutateWaitTimes,
    error,
  }
}
