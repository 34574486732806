import { Queue } from "@/api/interface/queue"
import { WaitTime } from "@/api/interface/wait-time"

export const mapqueueselectOptions = (
  queues: Array<Queue>,
  waitTimes: Array<WaitTime>
) => {
  if (!queues || !waitTimes) return []

  const sortedQueues = queues.sort((a, b) => {
    const aIndex = a.index
    const bIndex = b.index

    if (aIndex > bIndex) {
      return 1
    }

    if (aIndex < bIndex) {
      return -1
    }

    return 0
  })

  const options = sortedQueues.map((q) => {
    const queueLocked = q.maxCapacity === 0

    const queueFull =
      q.maxCapacity > 0 &&
      waitTimes.find((y) => y.queueId === q.id)?.waitingPartiesInQueue >=
        q.maxCapacity

    let label = q.name
    if (queueFull) label += ' (Full)'
    if (queueLocked) label += ' (Closed)'

    return {
      ...q,
      value: q.id,
      label,
      disabled: queueFull || queueLocked,
    }
  })
  return options
}

export const transformValuesForApi = (values): Partial<Queue> => {
  return {
    ...values,
    maxCapacity: parseInt(values.maxCapacity) || -1,
  }
}

export const transformValuesForDisplay = (values) => {

  return {
    ...values,
    maxCapacity: values.maxCapacity === -1 ? '' : values.maxCapacity,
    waitTimeAlgCustom: values.waitTimeAlgCustom || 5,
  }
}
